import http from "../axios-instance";

class SocialService {
   getSocials() {
      return http
         .get("/socials")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getAllSocials() {
      return http
         .get("/socials_all")
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   addSocial(social) {
      return http
         .post("/social", social)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateSocial(social) {
      return http
         .put("/social/" + social._id, social)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteSocial(social) {
      return http
         .delete("/social/" + social._id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new SocialService();
