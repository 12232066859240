import http from "../axios-instance";

class VtypeService {
   createVType(model) {
      return http
         .post("/vehicle/vtype", model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   updateVType(model) {
      return http
         .put(`/vehicle/vtype/${model._id}`, model)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getVTypes(params) {
      return http
         .get("/vehicle/vtype", { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteVType(model) {
      return http
         .delete(`/vehicle/vtype/${model._id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   selectedVTypes(params) {
      return http
         .get( `/vehicle/vtype/selected/${params?.generation ?? ""}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }
}

export default new VtypeService();
