import http from "../axios-instance";

class ShoppingCartService {

   get() {
      return http.get(`shop/cart`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   add_item({ product_id, quantity, selected_extra_options }) {
      return http.post("/shop/cart/add_item", { product_id, quantity, selected_extra_options })
        .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update({ shopping_cart_id, items }) {
      return http.post("/shop/cart/update", { shopping_cart_id, items })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

}

export default new ShoppingCartService();
