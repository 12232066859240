import http from "../axios-instance";

class ManufacturerService {
  create(model) {
    return http
      .post("/vehicle/manufacturer", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  
  update(model) {
    return http
      .put(`/vehicle/manufacturer/${model._id}`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }
  
  get(params) {
    return http
      .get("/vehicle/manufacture", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  deleteManufacture(model) {
    return http
      .delete(`/vehicle/manufacturer/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getSelected(params) {
    return http
      .get(`/vehicle/manufacturer/selected/${params?.vtype ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new ManufacturerService();
