import http from "../axios-instance";

export class PriceListService {
  
  static addItems(models = []) {
    if (!Array.isArray(models) || models.length === 0) {
      return Promise.reject(new Error("`models` must be an array of price list items!"))
    }
    
    return http
      .post("/price-list", {
        items: models
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static updateItem(model) {
    return http
      .patch(`/price-list`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static getPriceList(params) {
    return http
      .get("/price-list", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static deleteItem(model) {
    return http
      .delete(`/price-list/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
}
