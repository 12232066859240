import http from "../axios-instance"
import { TokenService } from ".."

class AuthService {
  login(user) {
    return http
      .post("/auth/signin", user)
      .then((res) => {
        console.log(res)
        if (res.data.access_token) {
          TokenService.setUser(res.data)
        }
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  adminAutoLogin(user) {
    return http
      .post("/auth/admin_auto_login", user)
      .then((res) => {
        if (res.data.access_token) {
          TokenService.setUser(res.data)
        }
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  logout() {
    TokenService.removeUser()
    return Promise.resolve("success")
  }
  
  register(user) {
    return http
      .post("/auth/signup", user)
      .then((res) => {
        if (res.data.access_token) {
          TokenService.setUser(res.data)
        }
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  refreshUserData() {
    return http
      .get("/auth/refresh_user_data")
      .then((res) => {
        let user = TokenService.getUser()
        let new_user = {
          ...res.data,
          access_token: user.access_token,
          refresh_token: user.refresh_token,
        }
        TokenService.setUser(new_user)
        return Promise.resolve(new_user)
      })
      .catch((err) => {
        TokenService.removeUser()
        return Promise.reject(err)
      })
  }
  
  sendVerificationCode() {
    return http
      .get("/auth/send_verification_code")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  checkVerificationCode(data) {
    return http
      .post("/auth/check_verification_code", data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  sendForgotPassCode(data) {
    return http
      .post("/auth/send_forgot_pass_code", data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  checkForgotPassCode(data) {
    return http
      .post("/auth/check_forgot_pass_code", data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  resetPassword(data) {
    return http
      .post("/auth/reset_password", data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getSiteData() {
    return http
      .get("/auth/get_site_data")
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  pushSubscribe(subscription) {
    return http
      .post("/push/subscribe", subscription)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
}

export default new AuthService()
