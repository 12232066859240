import http from "../axios-instance";

class ProductService {
  get_by_slug(slug) {
    return http
      .get("/shop/product/get_by_slug/" + slug)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_by_product_code(product_code) {
    return http
      .get("/shop/product/get_by_product_code/" + product_code)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_by_id(id) {
    return http
      .get("/shop/product/get_by_id/" + id)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  get_all({ name, category_id, status, sort_by, sort_order, page, size }) {
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);
    if (category_id) queryParams.append("category_id", category_id);
    if (status) queryParams.append("status", status);
    if (sort_by) queryParams.append("sort_by", sort_by);
    if (sort_order) queryParams.append("sort_order", sort_order);
    queryParams.append("page", page || 1);
    queryParams.append("size", size || 15);

    return http
      .get(`shop/product/get_all?${queryParams.toString()}`)
      .then((res) => {
        console.log(res.data);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  add({
    name,
    slug,
    image1,
    image2,
    image3,
    price,
    weight,
    weight_unit,
    short_description,
    description,
    product_code,
    category_id,
    extra_options,
    inventory,
    status,
  }) {
    const data = new FormData();
    data.append("name", name);
    data.append("image1", image1);
    data.append("image2", image2);
    data.append("image3", image3);
    data.append("price", price);
    data.append("weight", weight);
    data.append("weight_unit", weight_unit);
    data.append("short_description", short_description);
    data.append("description", description);
    data.append("slug", slug);
    data.append("product_code", product_code);
    data.append("category_id", category_id);
    data.append("extra_options", JSON.stringify(extra_options));
    data.append("status", status);
    data.append("inventory", inventory);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return http
      .post("/shop/product/add", data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  update_status({ id, status }) {
    return http
      .post("/shop/product/update_status", { id, status })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  update({
    _id,
    name,
    image1,
    image2,
    image3,
    price,
    weight,
    weight_unit,
    slug,
    short_description,
    description,
    status,
    product_code,
    category_id,
    extra_options,
    inventory,
  }) {
    const data = new FormData();
    data.append("id", _id);
    data.append("name", name);
    data.append("image1", image1);
    data.append("image2", image2);
    data.append("image3", image3);
    data.append("price", price);
    data.append("weight", weight);
    data.append("weight_unit", weight_unit);
    data.append("short_description", short_description);
    data.append("description", description);
    data.append("slug", slug);
    data.append("product_code", product_code);
    data.append("category_id", category_id);
    data.append("extra_options", JSON.stringify(extra_options));
    data.append("status", status);
    data.append("inventory", inventory);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return http
      .post("/shop/product/update", data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  delete({ id }) {
    return http
      .post("/shop/product/delete", { id })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new ProductService();
