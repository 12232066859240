import http from "../axios-instance";

class UserService {
  getUsers(params) {
    return http
      .get("/user", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getDealersWithGooglePlaceID(keyword, page, size) {
    return http
      .get("/dealers/with-google-place-id", {
        params: {
          keyword: keyword,
          page: page,
          size: size
        }
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getCustomers() {
    return http
      .get("/user/customers")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getAdmins() {
    return http
      .get("/user/admins")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateUser(user) {
    return http
      .put(`/user/${user._id}`, user)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  updateGooglePlaceID(userID, googlePlaceID) {
    return http
      .patch(`/user/${userID}/google-place-id/`, {
        google_place_id: googlePlaceID
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteUser(user) {
    return http
      .delete(`/user/${user._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  // Update //
  updateProfile(data) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return http
      .post("/user/update_profile", data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateAddress(data) {
    return http
      .post("/user/update_address", data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateBusiness(data) {
    return http
      .post("/user/update_business", data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updatePassword(data) {
    return http
      .post("/user/update_password", data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  changePasswordAdmin(data) {
    return http
      .post(`/user/${data._id}/change_password`, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getAllUsers() {
    return http
      .get("/user_all")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  exportAgentsList() {
    return http.get('/user/export-all').then((res) => {
      return Promise.resolve(res.data)
    }).catch((err) => {
      return Promise.reject(err)
    })
  }

  getInactiveUsers() {
    return http
      .get("/user/inactives")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteInactiveUsers(inactive_users) {
    return http
      .post("/user/delete-inactives", { inactive_users })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      })
  }
  
  updateMyTools(myTools) {
    return http
      .put("/user/my-tools/update", {
        tools: myTools
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      })
  }

}

export default new UserService();
