import http from "../axios-instance";

class ShippingMethodService {

   get_by_id(id) {
      return http.get("/shop/shipping_method/get_by_id/" + id)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   get_all({ name, status, page, size }) {
      return http.get(`shop/shipping_method/get_all?name=${name}&status=${status}&page=${page}&size=${size}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   add({
         name, local_shipping_cost,
         international_shipping_cost,
         local_shipping_time,
         international_shipping_time,
         charge_per_product, status
   }) {
      return http.post("/shop/shipping_method/add", {
        name, local_shipping_cost,
        international_shipping_cost,
        local_shipping_time,
        international_shipping_time,
        charge_per_product: charge_per_product === 'true',
        status
      })
        .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   update_status({ id, status }) {
      return http.post("/shop/shipping_method/update_status", { id, status })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   update({ id, name, local_shipping_cost, international_shipping_cost, local_shipping_time, international_shipping_time, charge_per_product, status }) {
      return http.post("/shop/shipping_method/update", {
        id, name, local_shipping_cost,
        international_shipping_cost,
        local_shipping_time,
        international_shipping_time,
        charge_per_product: charge_per_product === 'true',
        status
      })
        .then((res) => {
            return Promise.resolve(res.data);
        })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

   delete({ id }) {
      return http.post("/shop/shipping_method/delete", { id })
        .then((res) => {
            return Promise.resolve(res.data);
         })
        .catch((err) => {
           return Promise.reject(err);
        });
   }

}

export default new ShippingMethodService();
