import Preloader from "./comps/pre-loader/pre-loader.vue";
import ThemifyIcon from "vue-themify-icons";
import CookieLaw from "vue-cookie-law";
import { ApiService, TermsService } from "@/services";

export default {
  name: "app",
  components: {
    Preloader,
    ThemifyIcon,
    CookieLaw,
  },
  data() {
    return {
      cookie: "",
      locales: [
        "af",
        "ar",
        "bn",
        "zh",
        "cs",
        "da",
        "nl",
        "en",
        "fr",
        "de",
        "el",
        "he",
        "hi",
        "hu",
        "it",
        "ja",
        "jv",
        "lt",
        "fa",
        "pl",
        "pt-br",
        "pt",
        "ro",
        "ru",
        "sk",
        "es",
        "sv",
        "th",
        "tr",
        "uk",
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch("refreshSiteSettings");

    // checking if the user is customer and not admin
    if (this.user && this.user.role_id && this.user.role_id === 2) {
      await this.$store.dispatch("refreshShoppingCart");
    }

    this.$store.dispatch("refreshThemeSettings").then((data) => {
      let htmlElement = document.documentElement;
      let theme = localStorage.getItem("theme");

      if (!theme) {
        theme = this.settings.default_theme;
      }

      if (theme && theme === "custom") {
        const root = document.querySelector(":root");
        root.style.setProperty("--bg-color-9", data.card_bg_color);
        root.style.setProperty("--text-color-7", data.card_text_color);
        root.style.setProperty("--bg-color-2", data.header_bar_color);
        root.style.setProperty("--text-color-3", data.header_bar_text_color);
        root.style.setProperty("--vehicle_bg_color", data.vehicle_bg_color);
        root.style.setProperty("--vehicle_text_color", data.vehicle_text_color);
      } else {
        let theme_data = {
          ...data,
          header_top_color: "",
          header_bar_color: "",
          header_top_text_color: "",
          header_bar_text_color: "",
          left_bar_color: "",
          left_bar_text_color: "",
          card_bg_color: "",
          card_text_color: "",
          body_bg_color: "",
        };
        this.$store.commit("update_theme_settings", theme_data);
      }

      if (theme === "default") {
        htmlElement.setAttribute("theme", "default");
      } else if (theme === "red") {
        htmlElement.setAttribute("theme", "red");
      } else {
        htmlElement.setAttribute("theme", "dark");
      }
    });

    let locale = localStorage.getItem("locale");
    if (locale && this.locales.includes(locale)) {
      this.$i18n.locale = locale;
      document.documentElement.lang = locale;
    } else {
      this.$i18n.locale = "en";
      document.documentElement.lang = "en";
    }

    if (this.user?.role_id === 2) {
      ApiService.hasAccessToVehicleStatisticsApi()
        .then((data) => {
          this.$store.commit(
            "setHasAccessToVehicleStatisticsApi",
            data?.status === "success"
          );
        })
        .catch(() => {
          this.$store.commit("setHasAccessToVehicleStatisticsApi", false);
        });
    }

    TermsService.getTermsAndPolicy().then((data) => {
      this.cookie = data.cookie;
    });
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    user() {
      return this.$store.state.user;
    },
    body_bg_color() {
      return "background-color:" + this.theme.body_bg_color;
    },
    settings() {
      return this.$store.state.settings;
    },
  },
};
