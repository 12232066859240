import http from "../axios-instance";

class OpensService {
  getOpenTimes() {
    return http
      .get("/open_times")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  updateOpenTimes(opens) {
    return http
      .post("/open_times", opens)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getClosingDates() {
    return http
      .get("/closing-dates")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  addClosingDate(date) {
    return http
      .post("/closing-dates", date)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  updateClosingDate(date) {
    return http
      .patch("/closing-dates", date)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  deleteClosingDate(id) {
    return http
      .delete(`/closing-dates/${id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new OpensService();
