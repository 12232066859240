import http from '../axios-instance'

export class ApiService {
  
  static getApis(params) {
    return http
      .get("/access_api", { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static getUserApis(params) {
    return http
      .get(`/access_api_user`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static buyApiAccess(api) {
    return http
      .post("/access_api", api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static createApiAccess(api) {
    return http
      .post(`/access_api_create`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static updateApiAccess(api) {
    return http
      .put(`/access_api/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static updateApiAccessUser(api) {
    return http
      .put(`/access_api_user/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static updateApiAccessByUser(api) {
    return http
      .put(`/api-access-update-by-user/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static deleteApiAccess(api) {
    return http
      .delete(`/access_api/${api._id}`, api)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static checkSmtpConnection({ host, port, secure, user, pass }) {
    return http
      .post(`/check-smtp-connection`, { host, port, secure, user, pass })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static hasAccessToVehicleStatisticsApi() {
    return http
      .get(`/has-access-to-vehicle-statistics-api`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  static getAllQuotesThroughApi(keywords = "", page = 1, size = 20) {
    return http
      .get("/quotes-through-api", {
        params: {
          keywords,
          page,
          size
        }
      })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
}