import http from "../axios-instance";

export class SpecialEcuBrandService {
  
  static async create(ecuBrand) {
    await http
      .post("/special-ecu-brands", ecuBrand)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static async update(ecuBrand) {
    await http
      .put(`/special-ecu-brands/${ecuBrand._id}`, ecuBrand)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static get(params) {
    return http
      .get("/special-ecu-brands", { params: { ...params } })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static getForDropdown() {
    return http
      .get("/special-ecu-brands/for-dropdown")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  static delete(ecuBrand) {
    return http
      .delete(`/special-ecu-brands/${ecuBrand._id}`, ecuBrand)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
