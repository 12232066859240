import http from '../axios-instance'

export class InAppNotificationService {
  
  static getUserNotifications({ page, size }) {
    return http
      .get("/in-app-notification/user", {
        params: {
          page,
          size
        }
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
  static getAdminNotifications({ page, size }) {
    return http
      .get("/in-app-notification/admin", {
        params: {
          page,
          size
        }
      })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
  static markAsReadByAdmin(notifications) {
    return http
      .patch("/in-app-notification/read-by-admin", { notifications })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
  static markAsReadByUser(notifications) {
    return http
      .patch("/in-app-notification/read-by-user", { notifications })
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
  static markAllAsReadByAdmin() {
    return http
      .patch("/in-app-notification/read-by-admin")
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
  static markAllAsReadByUser() {
    return http
      .patch("/in-app-notification/read-by-user")
      .then(res => Promise.resolve(res.data))
      .catch(err => Promise.reject(err))
  }
  
}