import http from "../axios-instance";

export class OrderService {
  static async createOrder(order, onUploadProgress) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    };

    return http
      .post("/order", order, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static retryAutomation(id) {
    return http
      .post(`/order/${id}/automation_retry`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getOrders(keywords, status, page, size) {
    return http
      .get("/order", {
        params: {
          keywords,
          status,
          page,
          size,
        },
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getOrder(order_id) {
    return http
      .get(`/order/${order_id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getUserOrders(params) {
    return http
      .get(`/user_orders`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getPendingOrders(params) {
    return http
      .get("/pending_orders", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getCompleteOrders(keyword, status, page, size) {
    return http
      .get("/complete_orders", {
        params: {
          keyword,
          status,
          page,
          size,
        },
      })
      .then((res) => {
        console.log(res);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getWarningOrdersCount() {
    return http
      .get("/warning_orders_count")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getCancelledOrdersCount() {
    return http
      .get("/cancelled_orders_count")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static deleteOrder(order) {
    return http
      .delete(`/order/${order._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static submitOrder(id, order, onUploadProgress) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    };

    return http
      .put(`/order/${id}/submit_order`, order, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static completeLaterPayment(orderID) {
    return http
      .put(`/order/complete-later-payment`, {
        orderID,
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static cancelOrder(id) {
    return http
      .put(`/order/${id}/cancel_order`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static changeOrderStatus(id, data) {
    return http
      .put(`/order/${id}/change_status`, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static downloadOldFile(order) {
    return http
      .get(`/order/${order._id}/download_old_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, order.original_file, order.reg_no);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static downloadNewFile(order) {
    return http
      .get(`/order/${order._id}/download_new_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, order.modified_file, order.reg_no, true);
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getPendingOrdersCount() {
    return http
      .get("/pending_orders_count")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getUserPendingOrdersCount() {
    return http
      .get("/user_pending_orders_count")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static getCompleteOrdersCount() {
    return http
      .get("/complete_orders_count")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static download(data, file_name, reg_no = "", isModifiedFile = false) {
    let blob = new Blob([data], {
      type: "application/octet-stream",
    });

    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob);
    // let ext = file_name.split('.').pop()

    downloadElement.href = href;

    // let originalFileName = file_name.split('__fn__')[1] || ''
    /*(ext.toLowerCase() === 'zip' ? '' : originalFileName.replace('.' + ext, '') + '_') +*/

    // downloadElement.download = isModifiedFile ? `mod_${reg_no}.${ext}` : `${reg_no}.${ext}`
    downloadElement.download = file_name.split(/__fn__(.+)/)[1];
    document.body.appendChild(downloadElement);
    downloadElement.click();

    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }

  static downloadIniFile(orderId, regNo) {
    console.log({ orderId });
    return http
      .get(`/order/${orderId}/download-ini`, { responseType: "blob" })
      .then((res) => {
        const fileName = "order-" + regNo + ".ini";

        const blob = new Blob([res.data], {
          type: "text/plain",
        });

        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);

        downloadElement.href = href;

        downloadElement.download = fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click();

        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);

        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
